import React, { useState, useEffect, useCallback } from 'react';
import {
    Grid,
    Typography,
    Button,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    Modal,
    Box,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
} from '@mui/material';
import * as XLSX from 'xlsx';
import GetAppIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import { IconFileDownload } from '@tabler/icons';
import SearchIcon from '@mui/icons-material/Search';
import StatsCard from '../../ui-component/cards/StatsCard';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from '../../store/constant';
import { modeContext } from '../../context/modeContext';
import { DataGrid } from '@mui/x-data-grid';
const CardWrapper = styled(Paper)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#fafafa',
    padding: theme.spacing(2),
}));

const HistorialSolicitudes = () => {
    const [comentarioRechazo, setComentarioRechazo] = React.useState('');
    const [openModalCancelar, setOpenModalCancelar] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const [stats, setStats] = useState({
        NO_INICIADO: 0,
        PROGRAMADO: 0,
        EN_PROCESO: 0,
        PENDIENTE_POR_CONFIRMAR: 0,
        FINALIZADO: 0,
    });

    const [filtros, setFiltros] = useState({
        nroSolicitud: '',
        tipoMantenimiento: '',
        cliente: '',
        almacen: '',
        fechaSolicitud: '',
    });
    const [openDetalleModal, setOpenDetalleModal] = useState(false);
    const [detalleRows, setDetalleRows] = useState([]);
    
    const [rows, setRows] = useState([]);

    const handleFetchDetalle = async (idMantenimiento) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/obtener-fechas/${idMantenimiento}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: token,
                    empresa: tokenCompany,
                    "Cache-Control": "no-cache",
                    Pragma: "no-cache",
                    Expires: "0",
                },
            });
    
            if (!response.ok) {
                if (response.status === 404) {
                    setDetalleRows([]); 
                    setOpenDetalleModal(true);
                    return;
                }
                throw new Error("Error al obtener los detalles del mantenimiento.");
            }
    
            const data = await response.json();
            setDetalleRows(data);
            setOpenDetalleModal(true);
        } catch (error) {
            console.error("Error al obtener los detalles del mantenimiento:", error);
            alert("Error al obtener los detalles del mantenimiento.");
        }
    };
    
    const handlePreviewFile = async (idMantenimiento) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias//mantenimiento/${idMantenimiento}/archivo-adjunto`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                        empresa: tokenCompany,
                        cache: 'no-cache',
                        pragma: 'no-cache',
                        'cache-control': 'no-cache'
                    },
                }
            );
    
            if (!response.ok) {
                throw new Error("Error al obtener los archivos asociados.");
            }
    
            const data = await response.json();
    
            if (data && data.length > 0) {
                data.forEach((archivo) => {
                    window.open(archivo.signedUrl, "_blank");
                });
            } else {
                alert("No hay archivos asociados a este mantenimiento.");
            }
        } catch (error) {
            console.error("Error al previsualizar los archivos:", error);
            alert("No hay archivos asociados a este mantenimiento.");
        }
    };
    
    
    const { token, tokenCompany ,user} = React.useContext(modeContext)

    const columns = [
        { field: 'nroSolicitud', headerName: 'Nro. Solicitud', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'nombreUsuarioCreacion', headerName: 'Usuario Creacion', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'tipoMantenimiento', headerName: 'Tipo Mantenimiento', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'cliente', headerName: 'Cliente', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'almacen', headerName: 'Almacén', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'placa', headerName: 'Placa', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'estadoMantenimiento', headerName: 'Estado Mantenimiento', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'fechaSolicitud', headerName: 'Fecha Solicitud', flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'acciones',
            headerName: 'Acciones',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    <Tooltip title="Descargar Documentos">
                        <IconButton
                            color="primary"
                            onClick={() => handlePreviewFile(params.row.id)}
                        >
                            <GetAppIcon  />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver Detalle">
                        <IconButton
                            color="primary"
                            onClick={() => handleFetchDetalle(params.row.id)}
                        >
                            <LinkIcon />
                        </IconButton>
                    </Tooltip>
                    {params.row.estadoMantenimiento === 'No Iniciado' && (
                        <Tooltip title="Cancelar">
                            <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setOpenModalCancelar(true);
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    const fetchConteoEstados = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/conteo-estados`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token:token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            });
            const data = await response.json();

            const estadoStats = {
                NO_INICIADO: 0,
                PROGRAMADO: 0,
                EN_PROCESO: 0,
                PENDIENTE_POR_CONFIRMAR: 0,
                FINALIZADO: 0,
            };

            data.forEach((item) => {
                switch (item.estado) {
                    case 'No Iniciado':
                        estadoStats.NO_INICIADO = item.cantidad;
                        break;
                    case 'Programado':
                        estadoStats.PROGRAMADO = item.cantidad;
                        break;
                    case 'En Proceso':
                        estadoStats.EN_PROCESO = item.cantidad;
                        break;
                    case 'Pendiente por Confirmar':
                        estadoStats.PENDIENTE_POR_CONFIRMAR = item.cantidad;
                        break;
                    case 'Finalizado':
                        estadoStats.FINALIZADO = item.cantidad;
                        break;
                    default:
                        break;
                }
            });

            setStats(estadoStats);
        } catch (error) {
            console.error('Error al obtener los conteos de estados:', error);
        }
    };

    const formatNroSolicitud = (correlativo) => {
        return `SOL-MANT-${String(correlativo).padStart(6, '0')}`;
    };

    const fetchData = useCallback(() => {
        const query = new URLSearchParams();
        Object.keys(filtros).forEach((key) => {
            if (filtros[key]) {
                query.append(key, filtros[key]);
            }
        });

        const url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/listarResponsableAlmacen?${query.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                token:token,
                empresa: tokenCompany,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
            .then((res) => res.json())
            .then((response) => {
                setRows(response.map((row) => ({
                    id: row.id,
                    nombreUsuarioCreacion: row.NombreUsuarioCreacion,
                    nroSolicitud: formatNroSolicitud(row.Correlativo),
                    tipoMantenimiento: row.TipoMantenimiento,
                    cliente: row.TipoNegocio?.Nombre || '',
                    almacen: row.Almacene?.Nombre || '',
                    placa: row.Placa || 'N/A',
                    estadoMantenimiento: row.EstadoMantenimiento || '',
                    fechaSolicitud: new Date(row.FechaCreacion).toLocaleDateString('es-ES'),
                    detalleMantenimiento: row.DetalleMantenimientoMaquinaria || [], 
                })));
            })
            .catch((error) => console.error('Error al cargar los datos:', error));
    }, [filtros, token, tokenCompany]);

    useEffect(() => {
        fetchConteoEstados();
        fetchData();
    }, [fetchData]);

    const handleEstadoMantenimiento = async (nuevoEstado, row, comentarioFinalizado = '') => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimiento/${row.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany
                }
            });
    
            const mantenimiento = await response.json();
    
            console.log("Response completo:", response);
            console.log("Datos del mantenimiento:", mantenimiento);
            
            if (!response.ok) {
                throw new Error("Error al obtener el mantenimiento");
            }
    
            const body = {
                IdMantenimiento: row.id,
                nuevoEstado,
                ItemName: mantenimiento.Articulo?.ItemName,
                ItemCode: mantenimiento.Articulo?.ItemCode,
                Placa: mantenimiento.Placa,
                IdAlmacen: mantenimiento.IdAlmacen,
                IdNegocio: mantenimiento.IdNegocio,
                IdArticulo: mantenimiento.IdArticulo,
                IdControlSerie: mantenimiento.IdControlSerie, 
                Grupo: mantenimiento.Grupo, 
                Serie: mantenimiento.Serie 
            };
    
            if (nuevoEstado === 'Rechazar' || nuevoEstado === 'Cancelar' || nuevoEstado === 'Aprobar') {
                body.comentarioFinalizado = comentarioFinalizado;
            }
    
            const updateResponse = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/modificar-estado`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                },
                body: JSON.stringify(body)
            });
    
            const updateResult = await updateResponse.json();
            fetchConteoEstados();
            if (!updateResponse.ok) {
                throw new Error(updateResult.message || 'Error al actualizar el estado');
            }
    
            console.log('Estado actualizado:', updateResult);
            fetchData();
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
        }
    };
    const handleExportToExcel = () => {
        if (rows.length === 0) {
            alert("No hay datos para exportar.");
            return;
        }
    
        const exportData = rows.map((row) => {
            const detalles = Array.isArray(row.detalleMantenimiento) ? row.detalleMantenimiento : [];
            const sortedDetalles = detalles.sort((a, b) => new Date(b.FechaRegistro) - new Date(a.FechaRegistro));
            const ultimoEstado = sortedDetalles.length > 0 ? sortedDetalles[0].EstadoMantenimiento : 'Sin Detalle';
    
            return {
                'Nro. Solicitud': row.nroSolicitud,
                'Usuario Creación': row.nombreUsuarioCreacion,
                'Tipo Mantenimiento': row.tipoMantenimiento,
                'Cliente': row.cliente,
                'Almacén': row.almacen,
                'Placa': row.placa,
                'Estado Mantenimiento': row.estadoMantenimiento,
                'Último Detalle': ultimoEstado, 
            };
        });
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(exportData);
    
        XLSX.utils.book_append_sheet(workbook, worksheet, 'HistorialSolicitudes');
    
        XLSX.writeFile(workbook, 'HistorialSolicitudes.xlsx');
    };
    
    
    const handleFilterChange = (field, value) => {
        setFiltros((prev) => ({ ...prev, [field]: value }));
    };

    const handleDelete = (id) => {
        setRows((prev) => prev.filter((row) => row.id !== id));
    };

    return (
        <Container fixed>
            <Grid container spacing={gridSpacing} alignItems="center" marginBottom={2}>
                <Grid item xs={6}>
                    <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
                        Historial de Solicitudes Mantenimiento
                    </Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Tooltip title="Exportar a Excel">
                        <IconButton
                            size="large"
                            onClick={handleExportToExcel}
                            sx={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                marginRight: 2,
                                '&:hover': {
                                    backgroundColor: theme.palette.success.dark,
                                },
                            }}
                        >
                            <IconFileDownload />
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/solicitudes-mantenimiento')}
                    >
                        Nuevo
                    </Button>
                </Grid>

            </Grid>

            <Grid container spacing={gridSpacing} marginBottom={2}>
                {[
                    { title: 'Iniciado', color: theme.palette.warning.main, count: stats.NO_INICIADO },
                    { title: 'Programado', color: theme.palette.primary.main, count: stats.PROGRAMADO },
                    { title: 'En Proceso', color: '#4CAF50', count: stats.EN_PROCESO },
                    { title: 'Por Aprobar', color: theme.palette.info.main, count: stats.PENDIENTE_POR_CONFIRMAR },
                    { title: 'Finalizado', color: '#B0B0B0', count: stats.FINALIZADO },
                ].map((card, index) => (
                    <Grid item lg={2.4} md={2.4} sm={6} xs={12} key={index}>
                        <CardWrapper>
                            <StatsCard title={card.title} color={card.color} number={card.count} />
                        </CardWrapper>
                    </Grid>
                ))}
            </Grid>

            {/* <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Nro. Solicitud"
                        value={filtros.nroSolicitud}
                        onChange={(e) => handleFilterChange('nroSolicitud', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Tipo de Mantenimiento</InputLabel>
                        <Select
                            value={filtros.tipoMantenimiento}
                            onChange={(e) => handleFilterChange('tipoMantenimiento', e.target.value)}
                            label="Tipo de Mantenimiento"
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="Preventivo">Preventivo</MenuItem>
                            <MenuItem value="Correctivo">Correctivo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Cliente"
                        value={filtros.cliente}
                        onChange={(e) => handleFilterChange('cliente', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Almacén"
                        value={filtros.almacen}
                        onChange={(e) => handleFilterChange('almacen', e.target.value)}
                    />
                </Grid>
            </Grid> */}
   
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            textAlign: 'center',
                        },
                    }}
                />
            </div>
            <Modal
                        open={openModalCancelar}
                        onClose={() => setOpenModalCancelar(false)}
                    >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}><Typography variant="h5" mb={2}>
                                ¿Está seguro que desea Cancelar el mantenimiento?
                            </Typography>
                            <TextField
                                fullWidth
                                label="Comentarios"
                                value={comentarioRechazo} 
                                onChange={(e) => setComentarioRechazo(e.target.value)}
                                multiline
                                rows={3}
                                variant="outlined"
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    handleEstadoMantenimiento('Cancelar', selectedRow, comentarioRechazo); 
                                    setOpenModalCancelar(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenModalCancelar(false)}
                                sx={{ ml: 2 }}
                            >
                                Cerrar
                            </Button>
                        </Box>
                    </Modal>
                    <Modal
                        open={openDetalleModal}
                        onClose={() => setOpenDetalleModal(false)}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 800,
                                minHeight: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4,
                                display: 'flex',
                                flexDirection: 'column', 
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="h3" gutterBottom>
                                Detalles del Mantenimiento
                            </Typography>
                            <TableContainer component={Paper} sx={{ flex: 1, overflow: 'auto', mb: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Nro. Solicitud</TableCell>
                                            <TableCell align="center">Tipo Mantenimiento</TableCell>
                                            <TableCell align="center">Evento</TableCell>
                                            <TableCell align="center">Fecha Programada</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detalleRows.length > 0 ? (
                                            detalleRows.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{row.NumeroSolicitud}</TableCell>
                                                    <TableCell align="center">{row.TipoMantenimiento}</TableCell>
                                                    <TableCell align="center">{row.EstadoMantenimiento}</TableCell>
                                                    <TableCell align="center">
                                                        {new Date(row.FechaProgramada).toLocaleDateString('es-ES')}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={4}>
                                                    No hay detalles disponibles para este mantenimiento.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenDetalleModal(false)}
                                >
                                    Cerrar
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

        </Container>
    );
};

export default HistorialSolicitudes;
