import React, { useState, useEffect } from "react";
import { useTheme, styled } from '@mui/material/styles'
import {
  Grid,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "date-fns/locale/es";
import { DataGrid } from "@mui/x-data-grid";
import { listarParametros } from "../../services/Parametro";
import { modeContext } from "../../context/modeContext";
import { Search } from '@mui/icons-material'
import PickSerie from "../shared/serie/PickSerie";
import PickArticle from "../shared/article/PickArticle";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ArticleIcon from '@mui/icons-material/Article';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CommentIcon from '@mui/icons-material/Comment';
import StyledDropzone from "../utilities/DropZone";


const SolicitudesMantenimiento = () => {
  const [loading, setLoading] = React.useState(false);
  const [responsableAlmacen, setResponsableAlmacen] = useState("");
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [tipoIncidencia, setTipoIncidencia] = useState("");
  const [cuentaArea, setCuentaArea] = useState("");
  const [fechaSolicitud, setFechaSolicitud] = useState(new Date());
  const [cuentaAreaArticulo, setCuentaAreaArticulo] = useState("");
  const [codigoProducto, setCodigoProducto] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tipoAlmacenOptions, setTipoAlmacenOptions] = React.useState([])
  const [cuentaOptions, setCuentaOptions] = React.useState([]);
  const theme = useTheme()
  const customization = useSelector((state) => state.customization)
  const { token, tokenCompany ,user} = React.useContext(modeContext)
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false)
  const [openRegistrarModal, setOpenRegistrarModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  
  const [tipoMantenimiento, setTipoMantenimiento] = React.useState('')
  const [tipoAlmacenNuevo, setTipoAlmacenNuevo] = React.useState('Todos'); 
  const [tipoAlmacen, setTipoAlmacen] = React.useState('')
  const [cuenta, setCuenta] = React.useState('')
  const [almacen, setAlmacen] = React.useState('')
  const [articulo, setArticulo] = React.useState({
      CodArticle: '',
      IdArticle: '',
      Article: '',
      IdAlmacen:''
  })
  const [serie, setSerie] = React.useState({
      IdControlSerie: null,
      SerialNumber: '',
      Placa: ''
  });
  
  const [openSerie, setOpenSerie] = React.useState(false);
  
  const [comentarios, setComentarios] = React.useState('')
  const [comentariosRegistro, setComentariosRegistro] = React.useState('')
  const [archivo, setArchivo] = React.useState(null)
  const [attachedFile, setAttachedFile] = React.useState(null);
  const [disabledCuenta, setDisabledCuenta] = React.useState(false)
  const [almacenOptions, setAlmacenOptions] = React.useState([])
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [openFind, setOpenFind] = React.useState(false)
  const [disabledArticle, setDisabledArticle] = React.useState(true)
  const [estadoMantenimiento, setEstadoMantenimiento] = React.useState('');
  const [placa, setPlaca] = React.useState('');
  const [cuentaOptionsFiltro, setCuentaOptionsFiltro] = React.useState([]);
  const [dateInit, setDateInit] = React.useState(new Date())
  const [dateFin, setDateFin] = React.useState(new Date())
  const [almacenesCargados, setAlmacenesCargados] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const columnsPickSerie = [
    { field: 'Placa', headerName: 'Placa', width: 400 },
];

const handleAddArticulo = () => {
  if (!articulo.CodArticle || !serie.Placa || !articulo.Article) {
      setErrorMessage("Todos los campos son obligatorios.");
      return;
  }

  const nuevoArticulo = {
      id: articulos.length + 1, 
      CodProducto: articulo.CodArticle,
      Placa: serie.Placa,
      IdArticulo: articulo.IdArticle,
      Descripcion: articulo.Article,
      Stock: articulo.Stock,
      U_Devolucion: articulo.U_Devolucion,
      Grupo: articulo.Grupo,
      IdControlSerie: serie.IdControlSerie,
      SerialNumber: serie.SerialNumber, 
  };

  setArticulos([...articulos, nuevoArticulo]);

  setArticulo({
      CodArticle: "",
      IdArticle: "",
      Article: "",
      IdAlmacen: "",
  });
  setSerie({
      IdControlSerie: null,
      SerialNumber: "",
      Placa: "",
  });
  setErrorMessage("");
};


const handleRemoveArticulo = () => {
  setArticulos([]);
};

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };
  const handleSelectedSerie = (selectedSerie) => {
    setSerie({
        IdControlSerie: selectedSerie.id,
        SerialNumber: selectedSerie.SerialNumber,
        Placa: selectedSerie.Placa
    });
};

  React.useEffect(() => {
    console.log("username"+ user)
    console.log("username"+ JSON.stringify(user))
    const fetchTipoAlmacenYTransferenciaOptions = async () => {
        try {
            const resultTipoDeAlmacenes = await listarParametros(process.env.REACT_APP_ID_GRUPO_PARAMETRO_TIPO_ALMACENES, token, tokenCompany);
            setTipoAlmacenOptions(resultTipoDeAlmacenes);
        } catch (error) {
            console.error("Error al obtener los tipos de almacén:", error);
        }
    };
    fetchTipoAlmacenYTransferenciaOptions();
}, [token, tokenCompany]);

const fetchAlmacenes = async () => {
  let url = `${process.env.REACT_APP_API}business/api/almacen/getAlmacenEmpresa`;

  if (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente") {
      const tipoCuenta = tipoAlmacen === "Almacen-Proveedor" ? 'proveedor' : 'negocio';
      url += `?tipoDeAlmacen=${tipoAlmacen}&cuentaAreaId=${cuenta}&tipoCuenta=${tipoCuenta}`;
  }
  else if (tipoAlmacen === "Almacen-Empresa") {
      url += `?tipoDeAlmacen=${tipoAlmacen}`;
  }

  const options = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          token: token,
          empresa: tokenCompany,
          cache: 'no-cache',
          pragma: 'no-cache',
          'cache-control': 'no-cache'
      }
  };

  try {
      const res = await fetch(url, options);
      const response = await res.json();
      setAlmacenOptions(response);
      setAlmacenesCargados(true);
  } catch (error) {
      console.error('Error:', error);
  }
};

React.useEffect(() => {
  setCuenta('') 
  setAlmacen('') 
  setCuentaOptions([]) 
  setAlmacenOptions([]) 

  if (tipoAlmacen === "Almacen-Empresa") {
      setDisabledCuenta(true) 
      fetchAlmacenes()
  } else {
      fetchAlmacenes()
      setDisabledCuenta(false) 
  }
}, [tipoAlmacen])

React.useEffect(() => {
  if (tipoAlmacen === "Almacen-Empresa" || (cuenta && (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente" || tipoAlmacen === "Todos"))) {
      fetchAlmacenes()
  }
}, [cuenta, tipoAlmacen])

const fetchCuentasOptions = async () => {
    try {
        let urlNegocios = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoNegocioUsuario`;
        let urlProveedores = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoProveedorUsuario`;

        let cuentaOptionsMerged = [];
        let cuentaOptionsMergedFiltro = [];

        if (tipoAlmacen === "Almacen-Cliente") {
            const tipoNegocios = await fetch(urlNegocios, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache', 
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            }).then((res) => res.json());

            cuentaOptionsMerged = tipoNegocios.map((item) => ({ ...item, tipo: "negocio" }));
        } else if (tipoAlmacen === "Almacen-Proveedor") {
            const proveedores = await fetch(urlProveedores, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany
                }
            }).then((res) => res.json());

            cuentaOptionsMerged = proveedores.map((item) => ({ ...item, tipo: "proveedor" }));
        } else if (tipoAlmacen === "Todos") {
            const [tipoNegocios, proveedores] = await Promise.all([
                fetch(urlNegocios, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany
                    }
                }).then((res) => res.json()),
                fetch(urlProveedores, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany
                    }
                }).then((res) => res.json())
            ]);

            cuentaOptionsMerged = [
                ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
            ];
        }
        else if (tipoAlmacenNuevo==='Todos') {
            const [tipoNegocios, proveedores] = await Promise.all([
                fetch(urlNegocios, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany
                    }
                }).then((res) => res.json()),
                fetch(urlProveedores, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany
                    }
                }).then((res) => res.json())
            ]);

            cuentaOptionsMergedFiltro = [
                ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
            ];
        }
        setCuentaOptions(cuentaOptionsMerged);
        setCuentaOptionsFiltro(cuentaOptionsMergedFiltro)
    } 
    catch (error) {
        console.error("Error:", error);
    }
};
const handleOpenFind = () => {
    if (almacen) {
        setOpenFind(true);
    }
}
const handleGuardar = async () => {
  if (!articulos.length) {
    setErrorMessage("Debe añadir al menos un artículo a la lista.");
    return;
  }
  setLoading(true); 
  const promises = articulos.map(async (articulo) => {
    const mantenimientoData = {
      TipoAlmacen: tipoAlmacen,
      IdNegocio: cuenta,
      IdAlmacen: almacen,
      IdArticulo: articulo.IdArticulo,
      Descripcion: articulo.Descripcion,
      Comentarios: comentarios,
      FechaCreacion: new Date().toISOString(),
      TipoMantenimiento: tipoSolicitud,
      Placa: articulo.Placa,
      Cantidad: 1,
      U_Devolucion: articulo.U_Devolucion,
      Grupo: articulo.Grupo,
      ItemCode: articulo.CodProducto,
      ItemName: articulo.Descripcion,
      IdControlSerie: articulo.IdControlSerie,
      Serie: articulo.SerialNumber,
      TipoIncidencia: tipoIncidencia,
    };

    const formData = new FormData();
    Object.keys(mantenimientoData).forEach((key) => {
      formData.append(key, mantenimientoData[key]);
    });
    if (selectedFiles) {
      Array.from(selectedFiles).forEach((file) => {
        formData.append("attached", file);
      });
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/`,
        {
          method: "POST",
          headers: {
            token: token,
            empresa: tokenCompany,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Error al crear el mantenimiento."
        );
      }

      return await response.json();
    } catch (error) {
      console.error(
        `Error al crear el mantenimiento para el artículo ${articulo.CodProducto}:`,
        error
      );
      throw error;
    }
  });

  try {
    await Promise.all(promises);
    console.log("Todos los mantenimientos fueron creados exitosamente.");
    setArticulos([]);
    setComentarios("");
    setTipoMantenimiento("");
    setTipoAlmacen("");
    setCuenta("");
    setAlmacen("");
    setSerie({
      IdControlSerie: null,
      SerialNumber: "",
      Placa: "",
    });
    alert("Mantenimientos creados con éxito.");
    navigate("/solicitudes-mantenimiento-historial");
  } catch (error) {
    setErrorMessage(
      `Error al crear algunos mantenimientos: ${error.message}`
    );
  } finally {
    setLoading(false);
  }
};



React.useEffect(() => {
  if (tipoAlmacen) {
      fetchCuentasOptions();
  }
}, [tipoAlmacen]);

const handleSelectedArticle = (article) => {
  console.log("ARTICULO + " + JSON.stringify(article));
  if (article.Stock <= 0) {
      setOpenWarningModal(true);
  } else {
      setArticulo({
          IdAlmacen: article.IdAlmacen,
          CodArticle: article.CodArticulo,
          IdArticle: article.IdArticulo,
          Article: article.Articulo,
          Stock: article.Stock,
          Grupo: article.Grupo,
          U_Devolucion: article.U_Devolucion,
          IdNegocioArticulo: article.IdNegocio,
      });
  }
  setOpenFind(false); 
};
const handleDrop = (acceptedFiles) => {
  if (acceptedFiles.length > 0) {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }
};

const handleFileUpload = () => {
  if (selectedFiles.length === 0) {
    alert("No hay archivos seleccionados para subir");
    return;
  }
  console.log("Archivo seleccionado:", selectedFiles[0]);
};
const columns = [
  { 
    field: "CodProducto", 
    headerName: "Cod Producto", 
    width: 150, 
    headerAlign: "center", 
    align: "center" 
  },
  { 
    field: "Placa", 
    headerName: "Placa", 
    width: 150, 
    headerAlign: "center", 
    align: "center" 
  },
  { 
    field: "Descripcion", 
    headerName: "Descripción", 
    width: 200, 
    headerAlign: "center", 
    align: "center" 
  },
  {
    field: "actions",
    headerName: "Acciones",
    width: 150,
    headerAlign: "center", 
    align: "center",
    renderCell: (params) => (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => handleRemoveArticulo(params.row.id)}
      >
        Eliminar
      </Button>
    ),
  },
];

  return (
    <Container>
      <Typography  variant="h2"
                sx={{ color: theme.palette.primary.main }}
                style={{ marginLeft: "100px" }}>
        Solicitud Mantenimientos
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          borderBottom: 1,
          borderBottomColor: theme.palette.primary.main,
        }}>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          <ArticleIcon
                  style={{ marginBottom: -5, marginRight: 5 }}
                />Información General</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4} marginTop={1}>
                <TextField
                    fullWidth size="small" margin="normal"
                    label="Responsable Almacén"
                    value={user?.name || ""}
                    disabled
                    
                />
                </Grid>
            <Grid item xs={12} sm={4} marginTop={1}>
              <FormControl fullWidth size="small" margin="normal">
                <InputLabel>Tipo Solicitud Mantenimiento</InputLabel>
                <Select
                  value={tipoSolicitud}
                   labelId="lblTipoSolicitud"
                   label="Tipo Solicitud Mantenimiento"
                  onChange={(e) => setTipoSolicitud(e.target.value)}
                >
                  <MenuItem value="Preventivo">Preventivo</MenuItem>
                  <MenuItem value="Correctivo">Correctivo</MenuItem>
                  <MenuItem value="Predictivo">Predictivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}  marginTop={1} >
              <FormControl fullWidth size="small" margin="normal">
                <InputLabel>Tipo Incidencia</InputLabel>
                <Select
                   labelId="lblTipoIncidencia"
                   label="Tipo Incidencia"
                  value={tipoIncidencia}
                  onChange={(e) => setTipoIncidencia(e.target.value)}
                >
                  <MenuItem value="Accesorio">Accesorio</MenuItem>
                  <MenuItem value="Equipos">Equipos</MenuItem>
                  <MenuItem value="Otros">Otros</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
         sx={{
          borderBottom: 1,
          borderBottomColor: theme.palette.primary.main,
        }}>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          <WarehouseOutlinedIcon
                  style={{ marginBottom: -5, marginRight: 5 }}
                />
                Almacén Origen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
          <Grid item xs={3}>
             <FormControl fullWidth size="small" margin="normal"> 
                 <InputLabel id="lblTipoAlmacen">Tipo de Almacén</InputLabel>
                 <Select
                     labelId="lblTipoAlmacen"
                      label="Tipo de Almacén"
                      value={tipoAlmacen}
                      onChange={(event) => setTipoAlmacen(event.target.value)}
                                    >
                      {tipoAlmacenOptions.map((option) => (
                      <MenuItem key={option.id} value={option.valor_1 || option.nombre}>
                             {option.nombre}
                             </MenuItem>
                          ))}
                    </Select>
                 </FormControl>
            </Grid>
            <Grid item xs={3}>
                    <FormControl fullWidth size="small" margin="normal">
                    <InputLabel disabled={disabledCuenta}>Cuenta/Área</InputLabel>
                    <Select
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                        label="Cuenta/Área"
                        disabled={disabledCuenta}
                    >
                        <MenuItem value="">--Seleccionar--</MenuItem>
                        {cuentaOptions.map((option) => (
                        <MenuItem
                            key={
                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                            }
                            value={
                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                            }
                        >
                            {option.Nombre}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>         
                <Grid item xs={3}>
                    <FormControl fullWidth size="small" margin="normal"> 
                    <InputLabel>Almacén</InputLabel>
                    <Select
                        value={almacen}
                        onChange={(e) => setAlmacen(e.target.value)}
                        label="Almacén"
                    >
                        {almacenOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.nombre}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
            <Grid item xs={3}>
            <FormControl >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <DesktopDatePicker
                  label="Fecha Solicitud"
                  value={fechaSolicitud}
                  onChange={setFechaSolicitud}
                  renderInput={(params) => <TextField fullWidth size="small" margin="normal" {...params} />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}  sx={{
          borderBottom: 1,
          borderBottomColor: theme.palette.primary.main,
        }}>
          <Typography variant="h4"sx={{ color: theme.palette.primary.main }} >
          <AddBoxOutlinedIcon
                    style={{ marginBottom: -5, marginRight: 5 }}
                  />
            Nuevo Artículo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
          <Grid item xs={4}>
               <FormControl fullWidth size="small" margin="normal"> 
                <InputLabel htmlFor="txtCodArticulo">Cod. de producto</InputLabel>
                <OutlinedInput
                    id="txtCodArticulo"
                    type="text"
                    value={articulo.CodArticle}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleOpenFind}>
                        <Search />
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Cod. de producto"
                />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth size="small" margin="normal">
                <InputLabel htmlFor="txtSerie">Placa</InputLabel>
                <OutlinedInput
                    id="txtSerie"
                    type="text"
                    value={`${serie.Placa}`}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="buscar serie"
                        onClick={() => setOpenSerie(true)}
                        edge="end"
                        >
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Serie - Placa"
                />
                </FormControl>
            </Grid>

            <Grid item xs={2}>
                <TextField
                fullWidth size="small" margin="normal"
                label="Descripción"
                variant="outlined"
                value={articulo.Article}
                disabled
                />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Cantidad"
                type="number"
                value={cantidad}
                onChange={(e) => setCantidad(Number(e.target.value))}
              />
            </Grid> */}
            <Grid item xs={2} >
              <Button
                fullWidth
                sx={{ mt: 2 }} 
                margin="dense"
                variant="contained"
                color="primary"
                onClick={handleAddArticulo}
              >
                Añadir Artículo
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          <CommentIcon
                    style={{ marginBottom: -5, marginRight: 5 }}
                  />
                  Comentarios</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Comentarios"
            value={comentarios}
            onChange={(e) => setComentarios(e.target.value)}
          />
        </AccordionDetails>
      </Accordion>
      <PickArticle
                        open={openFind}
                        reload={true}
                        handleClose={() => { setOpenFind(false) }}
                        handleSelectedArticle={handleSelectedArticle}
                        url={`${process.env.REACT_APP_API}business/api/articulo/getArticles`}
                        param={{
                            IdNegocio: cuenta,
                            IdAlmacen: almacen
                        }}
                        columns={[
                            { field: 'Articulo', headerName: 'Producto', width: 200 },
                            { field: 'CodArticulo', headerName: 'Cod. Producto', width: 200 },
                            { field: 'NombreNegocio', headerName: 'Nombre Negocio', width: 200 },
                            { field: 'Almacen', headerName: 'Almacén', width: 150 },
                            { field: 'Categoria', headerName: 'Categoría', width: 150 },
                            { field: 'Stock', headerName: 'Stock', type: 'number', width: 90 },
                        ]}
                    />
      <PickSerie
                    open={openSerie}
                    handleClose={() => setOpenSerie(false)}
                    handleSelectedArticle={handleSelectedSerie}
                    url={`${process.env.REACT_APP_API}business/api/control_series/ListByArticle`}
                    param={{
                        IdNegocio: cuenta,
                        IdAlmacen: articulo.IdAlmacen,
                        IdArticulo: articulo.IdArticle
                    }}
                    columns={columnsPickSerie}
                />
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon /> }
        >
          <Typography variant="h4"  sx={{ color: theme.palette.primary.main }}>
          <AttachmentIcon
                    style={{ marginBottom: -5, marginRight: 5 }}
                  />
                  Adjuntar Foto</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <StyledDropzone onDrop={handleDrop} />
      <Typography variant="body2" sx={{ mt: 2 }}>
        Archivos seleccionados:
      </Typography>
      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>{file.name}</li>
        ))}
      </ul>
        </AccordionDetails>
      </Accordion>

      <DataGrid
        rows={articulos}
        columns={columns}
        autoHeight
        pageSize={5}
        rowsPerPageOptions={[5]}
      />

      <Grid container spacing={2} justifyContent="flex-end" mt={2}>
        <Grid item>
          <Button variant="contained" color="secondary">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
        <Button
        variant="contained"
        color="primary"
        onClick={handleGuardar}
        disabled={loading}
      >
        {loading ? "Solicitando..." : "Solicitar"}
      </Button>
        </Grid>
      </Grid>
      {errorMessage && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Container>
  );
};

export default SolicitudesMantenimiento;
